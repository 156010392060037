import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "style-guide/component/Sidebar";
import { MenuOutlined } from "@ant-design/icons";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleContentClick = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  const toggleLogoutPopup = () => {
    setIsLogoutPopupOpen((prev) => !prev);
  };

  const handleLogout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/");
  };

  return (
    <div className="flex h-screen relative">
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-800 z-20 transform transition-transform duration-300 md:translate-x-0 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <Sidebar toggleLogoutPopup={toggleLogoutPopup} />
      </div>

      <button
        onClick={toggleSidebar}
        className="md:hidden absolute top-4 left-4 z-30 p-2 text-black rounded-full"
      >
        <MenuOutlined />
      </button>

      {isLogoutPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-30">
          <div className="w-[400px] bg-white border border-gray-300 shadow-md p-4 rounded-lg flex flex-col gap-10">
            <Typography text="Are you sure you want to logout?" variant="LG" />
            <div className="flex flex-row gap-2 justify-end">
              <Button
                type="Default"
                buttonText="Cancel"
                onClick={toggleLogoutPopup}
              />
              <Button
                type="Primary"
                buttonText="Logout"
                onClick={handleLogout}
              />
            </div>
          </div>
        </div>
      )}

      <main
        className={`flex-1 transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : ""
        } md:ml-64`}
        onClick={handleContentClick}
      >
        <Outlet />
      </main>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-30 md:hidden z-10"
          onClick={toggleSidebar}
        />
      )}
    </div>
  );
};

export default Layout;
