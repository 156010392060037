import React, { useState, useEffect } from "react";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import Input from "style-guide/component/Input";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import Loading from "style-guide/component/Loader";
import TextArea from "style-guide/component/Textarea";
import { getSolutionAndObjective } from "../../../../redux/slice/overview";

interface ParsedDocumentProps {
  solution: string;
  setSolution: React.Dispatch<React.SetStateAction<string>>;
  objectives: string[];
  setObjectives: React.Dispatch<React.SetStateAction<string[]>>;
}

const ParsedDocument: React.FC<ParsedDocumentProps> = ({
  solution,
  objectives,
  setObjectives,
  setSolution,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [newObjective, setNewObjective] = useState<string>("");
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );
  const { businessObjectives } = useSelector(
    (state: RootState) => state.buildData
  );

  const { solutionAndObjective } = useSelector(
    (state: RootState) => state.overviewData
  );

  useEffect(() => {
    const fetchCriteriaRequirement = async () => {
      const response = await dispatch(getSolutionAndObjective());

      if (response === 500 || response === 502) {
        dispatch(getSolutionAndObjective());
      }
    };

    fetchCriteriaRequirement();
  }, []);

  useEffect(() => {
    if (solutionAndObjective) {
      setSolution(solutionAndObjective?.solution);
      setObjectives(solutionAndObjective.objectives);
    }
  }, [businessObjectives, solutionAndObjective]);

  const handleAddObjective = () => {
    if (newObjective.trim() !== "") {
      setObjectives((prev) => [...prev, newObjective]);
      setNewObjective("");
    }
  };

  const handleDeleteObjective = (index: number) => {
    setObjectives((prev) => prev.filter((_, i) => i !== index)); // Remove objective by index
  };

  return (
    <>
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <>
          {/* Instruction Section */}
          <div className="gap-2 flex flex-col justify-between p-4">
            <Typography
              text="Please review the details below."
              variant="Heading 3"
              className="text-black"
            />
            <Typography
              text="Take a moment to make sure everything looks correct. This will help us create an accurate evaluation template and personalized recommendations for you."
              variant="LG"
              className="text-black"
            />
          </div>

          <div className="flex flex-col gap-2">
            {/* Solution Type */}
            <div className="gap-2 flex flex-col p-4">
              <Typography
                text="What is the core business problem that you need to solve?"
                variant="Heading 4"
                className="text-black"
              />
              <TextArea
                value={solution}
                size="Large"
                readOnly
                className="text-sm w-full sm:w-[300px] md:w-[400px] lg:w-[650px] focus-none"
              />
            </div>

            {/* Objectives Section */}
            <div className="gap-2 flex flex-col p-4">
              <Typography
                text="What are the main objectives you’re hoping to achieve with this solution?"
                variant="Heading 4"
                className="text-black"
              />
              <div className="flex flex-col sm:flex-row gap-4">
                <Input
                  placeholder="Write an objective here"
                  size="Medium"
                  theme="light"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[650px]"
                  value={newObjective}
                  onChange={(e) => setNewObjective(e.target.value)}
                />
                <Button
                  type={newObjective.trim() ? "Default" : "Disabled"}
                  size="Medium"
                  buttonText="Add"
                  onClick={handleAddObjective}
                />
              </div>

              {/* Display existing and new objectives */}
              <div className="flex flex-col gap-2">
                {objectives.map((objective, index) => (
                  <div key={index} className="flex flex-row gap-4">
                    <div className="border border-gray-300 p-2 rounded-lg w-full sm:w-[300px] md:w-[400px] lg:w-[650px]">
                      <Typography
                        text={objective}
                        variant="Body"
                        className="text-black"
                      />
                    </div>
                    <Button
                      type="Default"
                      size="Medium"
                      icon
                      className="border-[1px] border-[#D9D9D9] text-black"
                      iconComponent={<DeleteOutlined />}
                      onClick={() => handleDeleteObjective(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ParsedDocument;
