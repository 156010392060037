export const apiUrl = {
  login: "/open/login/",
  upload_file: "/file-uploads/",
  solutionAndObjective: "/solution-and-objectives/", // get
  updateSolutionObjectives: "/solution-and-objectives/update/", //post for update
  weight: "/criteria-weights/", // CriteriaWeight
  criteriaAndReqirements: "/criteria-and-requirements/", // CriteriaAndReqirements
  rfpEvaluation: "/rfp-response-eval/", //rfp evaluation
  coreBusinessCheck: "/core-business-problem/check/",
  coreBusinessObjective: "/core-business-problem/check/sufficient/objectives/",
  generateSections: "/core-business-problem/generate/sections/",
  generateCriteria: "/core-business-problem/generate/criteria/",
  rfpScore: "/rfp-response-score/",
  generateRequirements: "/core-business-problem/generate/criteria/requirements/",
};
