import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import StyleGuide from "style-guide/style_guide";
import { ThemeProvider } from "theme/ThemeContext";
import Toast from "style-guide/component/Toast";
import ProjectOverview from "components/intake/overview";
import ProtectedRoute from "components/ProtectedRoute";
import LandingPage from "components/landingPage";
import BuildTemplate from "components/intake/template";
import Invite from "components/intake/invite";
import Login from "components/authentication/Login";
import EvaluationScorecard from "components/evaluation/Eval";
import Layout from "components/Layout";
import UploadResponse from "components/intake/uploadResponse";
import BuildTemplateScratch from "components/intake/buildScratch/overview";
import Template from "components/intake/buildScratch/template";
import RequirementFlow from "components/intake/buildScratch/requirement";
import RfpType from "components/landingPage/RFP";
import CreateReport from "components/evaluation/CreateReport";
import VendorSummary from "components/evaluation/ScorecardReport";
import Eval from "components/intake/buildScratch/refine";

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Toast />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="style_guide" element={<StyleGuide />} />
          <Route
            path="/landing_page"
            element={<ProtectedRoute component={LandingPage} />}
          />
          <Route
            path="/choose_RFP"
            element={<ProtectedRoute component={RfpType} />}
          />
          <Route
            path="/project_overview"
            element={<ProtectedRoute component={ProjectOverview} />}
          />
          <Route
            path="/build_templates"
            element={<ProtectedRoute component={BuildTemplate} />}
          />
          <Route
            path="/upload_response"
            element={<ProtectedRoute component={UploadResponse} />}
          />
          <Route
            path="/invite"
            element={<ProtectedRoute component={Invite} />}
          />
          <Route
            path="/build_scratch/overview"
            element={<ProtectedRoute component={BuildTemplateScratch} />}
          />
          <Route
            path="/build_scratch/template"
            element={<ProtectedRoute component={Template} />}
          />
          <Route
            path="/build_scratch/requirements_criteira"
            element={<ProtectedRoute component={RequirementFlow} />}
          />
          <Route path="/eval" element={<ProtectedRoute component={Eval} />} />
          <Route
            path="/create_report"
            element={<ProtectedRoute component={CreateReport} />}
          />
          <Route
            path="/get_report"
            element={<ProtectedRoute component={VendorSummary} />}
          />
          <Route element={<Layout />}>
            <Route
              path="evaluation"
              element={<ProtectedRoute component={EvaluationScorecard} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
