import { BookTwoTone, DiffTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Menu from "style-guide/component/Menu";
import Typography from "style-guide/component/Typography";

interface OptionProps {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
}

const OptionCard: React.FC<OptionProps> = ({ icon, text, onClick }) => (
  <div
    className="w-full sm:w-[250px] md:w-[350px] border-[1px] border-[#D9D9D9] gap-4 md:gap-10 rounded-lg p-6 flex flex-col justify-between hover:border-blue-primary cursor-pointer"
    onClick={onClick}
  >
    {icon}
    <Typography variant="LG" text={text} />
  </div>
);

const RfpType: React.FC = () => {
  const navigate = useNavigate();

  const options: OptionProps[] = [
    {
      icon: (
        <BookTwoTone twoToneColor="#0F428D" style={{ fontSize: "40px" }} />
      ),
      text: "Free form RFP.",
      onClick: () => navigate("/project_overview"),
    },
    {
      icon: (
        <DiffTwoTone twoToneColor="#0F428D" style={{ fontSize: "40px" }} />
      ),
      text: "RFP with defined Yes/No and text responses.",
    },
  ];

  return (
    <div className="w-full p-4 sm:p-6 md:p-12 font-helvetica-neue flex flex-col gap-8 sm:gap-8">
      <div className="flex flex-row justify-between items-center">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <div className="bg-pink gap-2 flex flex-col justify-between rounded-md">
        <Typography
          text="What is the type of RFP you want to analyze?"
          variant="Heading 3"
          className="text-black text-lg sm:text-xl md:text-2xl"
        />
        <Typography
          text="Get started with one of the options below."
          variant="LG"
          className="text-black"
        />
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4 items-center md:items-stretch">
        {options.map((option, index) => (
          <OptionCard
            key={index}
            icon={option.icon}
            text={option.text}
            onClick={option.onClick}
          />
        ))}
      </div>
    </div>
  );
};

export default RfpType;
