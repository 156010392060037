import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import Breadcrumb from "style-guide/component/Breadcrumb";
import Typography from "style-guide/component/Typography";
import Question from "./question";
import Button from "style-guide/component/Button";
import QuestionProgress from "style-guide/component/QuestionProgress";
import {
  getRefinementQuestion,
  setRefinementQuestion,
} from "../../../../redux/slice/build";
import Loading from "style-guide/component/Loader";
import Menu from "style-guide/component/Menu";

const Eval = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { refinementQuestion } = useSelector(
    (state: RootState) => state.buildData
  );
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  const initialQuestionIndex =
    Number(searchParams.get("question")) ||
    Number(localStorage.getItem("currentQuestion")) ||
    0;

  const [currentQuestion, setCurrentQuestion] = useState(initialQuestionIndex);

  const [answers, setAnswers] = useState<string[]>(
    JSON.parse(localStorage.getItem("answers") || "[]") ||
      Array(refinementQuestion.length).fill("")
  );

  const handleNext = async () => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = answers[currentQuestion] || "";

    setAnswers(updatedAnswers);
    localStorage.setItem("answers", JSON.stringify(updatedAnswers));

    if (currentQuestion < refinementQuestion.length - 1) {
      const nextQuestion = currentQuestion + 1;
      setCurrentQuestion(nextQuestion);
      setSearchParams({ question: nextQuestion.toString() });
      localStorage.setItem("currentQuestion", nextQuestion.toString());
    } else {
      const payload = refinementQuestion.map((q: any, index: number) => ({
        question: q.question || "",
        answer: updatedAnswers[index] || "",
      }));

      const response = await dispatch(setRefinementQuestion(payload));

      if (response === 200) {
        localStorage.removeItem("answers");
        localStorage.removeItem("currentQuestion");
        navigate(
          "/build_scratch/requirements_criteira/?component=add_requirement"
        );
      }
    }
  };

  const handleInputChange = (value: string) => {
    setAnswers((prev) => {
      const updated = [...prev];
      updated[currentQuestion] = value;
      // localStorage.setItem("answers", JSON.stringify(updated));
      return updated;
    });
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      const previousQuestion = currentQuestion - 1;
      setCurrentQuestion(previousQuestion);
      setSearchParams({ question: previousQuestion.toString() });
      localStorage.setItem("currentQuestion", previousQuestion.toString()); // Save question index
    } else {
      navigate(
        "/build_scratch/requirements_criteira/?component=add_requirement"
      );
    }
  };

  useEffect(() => {
    dispatch(getRefinementQuestion());
  }, []);

  useEffect(() => {
    if (refinementQuestion && refinementQuestion.length > 0) {
      const savedAnswers = JSON.parse(localStorage.getItem("answers") || "[]");
      if (savedAnswers.length === refinementQuestion.length) {
        setAnswers(savedAnswers);
      } else {
        const initialAnswers = refinementQuestion.map(
          (q: any) => q.answer || ""
        );
        setAnswers(initialAnswers);
        localStorage.setItem("answers", JSON.stringify(initialAnswers)); // Initialize in localStorage
      }
    }
  }, [refinementQuestion]);

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] pt-6 px-6 md:px-12 font-helvetica-neue flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <Breadcrumb
        items={[
          {
            label: "Evaluation Strategy",
            link: "",
            isActive: false,
          },
          { label: "Refine Requirements", link: "/", isActive: true },
        ]}
        theme="light"
      />
      <QuestionProgress
        value={currentQuestion + 1}
        max={refinementQuestion.length}
      />

      {currentQuestion === 0 && (
        <div className="flex flex-col gap-2">
          <Typography
            variant="Heading 3"
            text="To better tailor the evaluation to your specific use case, we have a few additional questions for you."
          />
          <Typography
            variant="LG"
            text="Your responses will help us generate a targeted evaluation strategy that aligns with your unique needs."
          />
        </div>
      )}
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <Question
          question={refinementQuestion[currentQuestion]?.question}
          placeholder={refinementQuestion[currentQuestion]?.sample_answer?.text}
          value={answers[currentQuestion] || ""} // Use the `answers` state
          onChange={handleInputChange}
        />
      )}
      <div
        className={`h-[72px] fixed bottom-0 left-0 right-0 flex flex-row items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px] justify-between`}
      >
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type={isLoading ? "Disabled" : "Primary"}
          size="Large"
          icon={false}
          buttonText={
            currentQuestion === refinementQuestion.length - 1
              ? "Finish"
              : "Next"
          }
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default Eval;
