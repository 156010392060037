import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import Menu from "style-guide/component/Menu";
import ProgressBar from "style-guide/component/Progressbar";
import Typography from "style-guide/component/Typography";
import Button from "style-guide/component/Button";
import CoreBusiness from "./CoreBusiness";
import ParsedDocument from "./ParsedDocument";
import { setBusinessProblemQueries } from "../../../../redux/slice/build";
import { setIntakeObjectives } from "../../../../redux/slice/overview";

export type Query = {
  question: string;
  suggestion: string;
  answer: string;
};

type BusinessProblemQuestion = {
  queries: Query[];
};

const BuildTemplateScratch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [solution, setSolution] = useState<string>("");
  const [objectives, setObjectives] = useState<string[]>([]);
  const [error, setError] = useState<string>("");

  const { businessProblemQuestion } = useSelector(
    (state: RootState) => state.buildData
  ) as { businessProblemQuestion: BusinessProblemQuestion };
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );
  const [answers, setAnswers] = useState<Query[]>(
    businessProblemQuestion?.queries?.map((q) => ({
      question: q.question,
      suggestion: q.suggestion,
      answer: "",
    })) || []
  );

  const queryParams = new URLSearchParams(location.search);
  const initialComponent =
    queryParams.get("component") === "parseDocument"
      ? "parseDocument"
      : "coreBusiness";

  const [currentComponent, setCurrentComponent] = useState<
    "coreBusiness" | "parseDocument"
  >(initialComponent);

  const handleBack = () => {
    if (currentComponent === "coreBusiness") {
      navigate("/landing_page");
    } else {
      navigate("/build_scratch/overview/?component=coreBusiness");
      setCurrentComponent("coreBusiness");
    }
  };

  const handleNext = async () => {
    if (currentComponent === "coreBusiness") {
      const data = { queries: answers, solution };
      const response = await dispatch(setBusinessProblemQueries({ data }));
      if (
        response?.status === 200 &&
        response.data.Is_problem_statement_sufficient
      ) {
        navigate("/build_scratch/overview/?component=parseDocument");
        setCurrentComponent("parseDocument");
        setError("");
      } else if (!response?.data?.Is_problem_statement_sufficient) {
        setError("Core business solution must be valid");
      }
    } else {
      const data = {
        objectives,
        solution,
      };
      const response = await dispatch(setIntakeObjectives(data));
      if (response === 200) {
        setCurrentStep(2);
        navigate("/build_scratch/template/?component=category");
      }
    }
  };

  const updateAnswers = (index: number, value: string) => {
    setAnswers((prev) =>
      prev.map((answer, i) =>
        i === index ? { ...answer, answer: value } : answer
      )
    );
  };

  useEffect(() => {
    if (businessProblemQuestion?.queries?.length > 0 && answers.length === 0) {
      setAnswers(
        businessProblemQuestion?.queries?.map((item) => ({
          question: item.question,
          suggestion: item.suggestion,
          answer: item.answer,
        }))
      );
    }
  }, [businessProblemQuestion]);

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] pt-12 px-6 md:px-12 font-helvetica-neue flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <ProgressBar currentStep={currentStep} />

      <div className="flex-grow">
        {currentComponent === "coreBusiness" && (
          <CoreBusiness
            question={businessProblemQuestion?.queries}
            answers={answers}
            setAnswers={setAnswers}
            setSolution={setSolution}
            solution={solution}
            error={error}
            setError={setError}
            updateAnswers={updateAnswers}
          />
        )}
        {currentComponent === "parseDocument" && (
          <ParsedDocument
            solution={solution}
            setSolution={setSolution}
            setObjectives={setObjectives}
            objectives={objectives}
          />
        )}
      </div>

      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <div className="flex flex-row gap-4">
          <Button
            type={isLoading ? "Disabled" : "Primary"}
            size="Large"
            icon={false}
            buttonText="Next"
            onClick={handleNext}
          />
        </div>
      </div>
    </div>
  );
};

export default BuildTemplateScratch;
