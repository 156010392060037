import React, { useEffect } from "react";
import Typography from "style-guide/component/Typography";
import TextArea from "style-guide/component/Textarea";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getBusinessProblemQueries } from "../../../../redux/slice/build";
import Loading from "style-guide/component/Loader";
import { Query } from ".";

interface CoreBusinessProps {
  error: string;
  solution: string;
  setSolution: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
  question: Query[];
  answers: Query[];
  updateAnswers: (index: number, value: string) => void;
  setAnswers: React.Dispatch<React.SetStateAction<Query[]>>;
}

const CoreBusiness: React.FC<CoreBusinessProps> = ({
  setSolution,
  solution,
  error,
  setError,
  question,
  updateAnswers,
  answers,
  setAnswers,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { businessProblemQuestion } = useSelector(
    (state: RootState) => state.buildData
  );

  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  useEffect(() => {
    dispatch(getBusinessProblemQueries());
  }, []);

  useEffect(() => {
    if (businessProblemQuestion?.solution) {
      setSolution(businessProblemQuestion.solution);
    }
  }, [businessProblemQuestion]);

  useEffect(() => {
    if (businessProblemQuestion?.queries?.length > 0) {
      setAnswers(
        businessProblemQuestion?.queries?.map((item: Query) => ({
          question: item.question,
          suggestion: item.suggestion,
          answer: item.answer,
        }))
      );
    }
  }, [businessProblemQuestion, answers.length]);

  const handleInputChange = (value: string, index: number) => {
    setError("");
    updateAnswers(index, value);

    const combinedSolution = answers
      .map((item) => item.answer)
      .filter((val) => val?.trim() !== "")
      .join(" ");

    if (combinedSolution !== solution) {
      setSolution(combinedSolution);
    }
  };

  useEffect(() => {
    const combinedSolution = answers
      .map((item) => item.answer)
      .filter((val) => val?.trim() !== "")
      .join(" ");

    if (combinedSolution !== solution) {
      setSolution(combinedSolution);
    }
  }, [answers]);

  return (
    <>
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <>
          <div className="gap-2 flex flex-col justify-between p-4">
            <Typography
              text="What business problem are you trying to solve?"
              variant="Heading 3"
              className="text-black"
            />
            <Typography
              text="Describe it briefly to help tailor your evaluation template."
              variant="LG"
              className="text-black"
            />
          </div>

          <div className="flex flex-col md:flex-row w-full gap-4">
            {/* Left Section - 60% */}
            <div className="w-full md:w-3/5 p-4 flex flex-col gap-4">
              {question?.length > 0 &&
                question.map((item, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <Typography variant="Heading 4" text={item?.question} />
                    <div className="flex flex-col gap-1">
                      <Typography variant="Base" text="Example:" strong />
                      <Typography variant="Base" text={item?.suggestion} />
                    </div>
                    <TextArea
                      value={answers[index]?.answer}
                      size="Large"
                      onChange={(e) => handleInputChange(e.target.value, index)}
                      className="text-sm w-full"
                    />
                  </div>
                ))}
            </div>

            {/* Right Section - 40% */}
            <div className="w-full md:w-2/5 p-4 flex flex-col gap-2">
              <Typography
                text="What is the core business problem that you need to solve?"
                variant="Heading 4"
                className="text-black"
              />
              <TextArea
                value={solution}
                size="Large"
                readOnly
                className="text-sm w-full focus-none h-[fit-content]"
              />
              {error && (
                <Typography
                  variant="Base"
                  text={error}
                  className="text-[red]"
                  strong
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CoreBusiness;
