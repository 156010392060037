import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { api } from "api/axiosInterceptor";
import {
  decrementRequests,
  incrementRequests,
  setShowToast,
} from "./application";
import {
  IEvalResponse,
  IEvalScores,
  IEvaluationState,
} from "../../types/evaluation";
import { apiUrl } from "api/apiUrl";

const initialState: IEvaluationState = {
  evalData: {},
  evalScores: {},
  generateReport: {},
  vendorDetail: {},
};

const slice = createSlice({
  name: "evalutaion",
  initialState,
  reducers: {
    rfpEvaluation(state, action: PayloadAction<IEvalResponse>) {
      state.evalData = action.payload;
    },
    rfpScores(state, action: PayloadAction<IEvalScores>) {
      state.evalScores = action.payload;
    },
    generateReportCalculationResponse(state, action) {
      state.generateReport = action.payload;
    },
    getVendorDetails(state, action) {
      state.vendorDetail = action.payload;
    },
  },
});

export const {
  rfpEvaluation,
  rfpScores,
  generateReportCalculationResponse,
  getVendorDetails,
} = slice.actions;

export default slice.reducer;

export function rfpResponseEvaluation(files: string[]) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl.rfpEvaluation;
      const response = await api.post(path, files);
      dispatch(rfpEvaluation(response.data));
      return response.status;
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      if (status !== 500 && status !== 502 && status !== 400) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      }
      return status;
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function rfpResponseScore(files: string[]) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl.rfpScore;
      const response = await api.post(path, files);
      dispatch(rfpScores(response.data));
      return response.status;
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      if (status !== 500 && status !== 502 && status !== 400) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      }
      return status;
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getCreateReport() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = "/generate-report-calculation/";
      const response = await api.get(path);
      dispatch(generateReportCalculationResponse(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getVendorSummary(vendorName: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/vendor-summary/${vendorName}/`;
      const response = await api.get(path);
      dispatch(getVendorDetails(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}
