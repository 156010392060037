import React from "react";
import clsx from "clsx";
import Typography from "./Typography";

type BreadcrumbProps = {
  items: { label: string; link?: string; isActive?: boolean }[];
  theme?: "light" | "dark";
  className?: string;
};

const themeClasses = {
  light: {
    active: "text-blue-primary",
    inactive: "text-gray-600",
    separator: "text-gray-400",
  },
  dark: {
    active: "text-blue-400",
    inactive: "text-gray-300",
    separator: "text-gray-500",
  },
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  items,
  theme = "light",
  className = "",
}) => {
  return (
    <nav className={clsx("flex items-center space-x-2", className)}>
      {items.map((item, index) => (
        <span key={index} className="flex items-center">
          {item.link && !item.isActive ? (
            <Typography variant="Base" text={item.label} />
          ) : (
            <Typography
              variant="Base"
              text={item.label}
              className={clsx(
                item.isActive ? "text-blue-primary" : ""
              )}
            />
          )}
          {index < items.length - 1 && (
            <span className={clsx(themeClasses[theme].separator, "mx-2")}>
              /
            </span>
          )}
        </span>
      ))}
    </nav>
  );
};

export default Breadcrumb;
