import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCreateReport } from "../../redux/slice/evaluation";
import { AppDispatch, RootState } from "redux/store";
import Breadcrumb from "style-guide/component/Breadcrumb";
import Button from "style-guide/component/Button";
import Menu from "style-guide/component/Menu";
import Typography from "style-guide/component/Typography";
import { StarFilled } from "@ant-design/icons";
import Loading from "style-guide/component/Loader";

interface ReportCalculation {
  [category: string]: {
    [criteria: string]: {
      total_weight: number;
      scorers: string[];
      rfp_responses: {
        [companyName: string]: { score: number };
      };
    };
  };
}

interface SelectedItem {
  companyName: string;
  score: number;
}

const CreateReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);
  const [Finish, setFinish] = useState<boolean>(false);

  const { generateReport } = useSelector(
    (state: RootState) => state.evaluationData
  );

  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  const reportCalculation = generateReport?.report_calculation as
    | ReportCalculation
    | undefined;

  const handleSelectCompany = (companyName: string, score: number) => {
    setSelectedItem({ companyName, score });
  };

  const handleNext = async () => {
    setTimeout(() => {
      setFinish(false);
      if (selectedItem) {
        navigate(`/get_report?vendor=${selectedItem.companyName}`);
      }
    }, 3000);
    setFinish(true);
  };

  useEffect(() => {
    dispatch(getCreateReport());
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : Finish ? (
        <div className="fixed inset-0 flex items-center justify-center flex-col gap-2">
          <Loading />
          <Typography text="Generating your report..." variant="XS" />
        </div>
      ) : (
        <div className="overflow-x-auto w-full min-h-screen pb-16 pt-8 px-4 sm:px-6 md:px-12 font-helvetica-neue flex flex-col gap-6">
          <div className="flex flex-row justify-between items-center">
            <Typography
              text="Nozma"
              variant="LG"
              className="text-blue-primary"
              strong
            />
            <Menu />
          </div>
          <Breadcrumb
            items={[
              {
                label: "Evaluation Scorecard",
                link: "/evaluation",
                isActive: false,
              },
              { label: "Create Report", link: "/products", isActive: true },
            ]}
            theme="light"
          />
          <Typography
            variant="Heading 2"
            text="Which solution would you like to select?"
          />
          <div className="flex flex-col gap-4 sm:gap-2">
            {reportCalculation &&
              (() => {
                const highestScoringCompany = Object.entries(
                  generateReport.companies as Record<string, number>
                ).reduce(
                  (max, [companyName, score]) =>
                    score > max.score ? { companyName, score } : max,
                  { companyName: "", score: 0 }
                );

                return Object.entries(
                  generateReport.companies as Record<string, number>
                ).map(([companyName, score], index) => {
                  const isHighest =
                    companyName === highestScoringCompany.companyName;
                  const isSelected = selectedItem?.companyName === companyName;

                  return (
                    <div
                      className={`p-4 border ${
                        isSelected ? "border-blue-500" : "border-[#D9D9D9]"
                      } w-full sm:w-[80%] md:w-[60%] lg:w-[40%] flex flex-row justify-between rounded-lg cursor-pointer`}
                      key={index}
                      onClick={() => handleSelectCompany(companyName, score)}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <Typography variant="LG" text={companyName} strong />
                        {isHighest && <StarFilled className="text-[#FAAD14]" />}
                      </div>
                      <div className="flex flex-row gap-2">
                        <Typography variant="Base" text="Score:" />
                        <Typography variant="Base" text={`${score}`} strong />
                      </div>
                    </div>
                  );
                });
              })()}
          </div>

          <div className="mt-8">
            <Typography variant="Heading 3" text="Weighted Score Breakdown" />
          </div>

          <div className="overflow-x-auto">
            <table className="w-[70%] text-left border-collapse">
              <thead>
                <tr>
                  <th className="p-4 w-[300px]">
                    <Typography variant="Base" text="Section" strong />
                  </th>
                  <th className="p-4 w-[150px]">
                    <Typography variant="Base" text="Weight" strong />
                  </th>
                  {Object.keys(generateReport?.companies || {}).map(
                    (companyName) => (
                      <th
                        className="w-[150px] p-4 hidden md:table-cell"
                        key={companyName}
                      >
                        <Typography variant="Base" text={companyName} strong />
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {reportCalculation &&
                  Object.entries(reportCalculation).map(
                    ([category, criteriaList], index) => {
                      return (
                        <tr key={index} className="border-t border-b">
                          <td className="p-4 border-r border-[#0000000F] w-[300px]">
                            <Typography variant="Base" text={category} strong />
                          </td>
                          <td className="p-4 border-r border-[#0000000F] w-[150px]">
                            {`${Number(criteriaList.total_weight).toFixed(2)}%`}
                          </td>
                          {Object.values(criteriaList.rfp_responses).map(
                            (companyData, indexTesting) => {
                              const score =
                                typeof companyData === "object" &&
                                companyData !== null &&
                                "score" in companyData
                                  ? companyData.score
                                  : companyData;

                              return (
                                <td
                                  className="p-4 border-r border-[#0000000F] w-[150px]"
                                  key={indexTesting}
                                >
                                  {typeof score === "number" ? score : 0}{" "}
                                </td>
                              );
                            }
                          )}
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>

          <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-col sm:flex-row justify-between items-center bg-white px-4 sm:px-6 md:px-12 py-2 sm:py-4 border-t-[#D9D9D9] border-t-[1px] gap-2 sm:gap-0">
            <Button
              type="Default"
              size="Large"
              icon={false}
              buttonText="Cancel"
              onClick={() => navigate(-1)}
              className="w-full sm:w-auto"
            />
            <div className="flex flex-row gap-4 w-full sm:w-auto justify-center sm:justify-end">
              <Button
                type={!selectedItem ? "Disabled" : "Primary"}
                size="Large"
                icon={false}
                buttonText="Next"
                className="w-full sm:w-auto"
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateReport;
