import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIntakeObjectives } from "../../../redux/slice/overview";
import { AppDispatch, RootState } from "redux/store";
import Menu from "style-guide/component/Menu";
import ProgressBar from "style-guide/component/Progressbar";
import Typography from "style-guide/component/Typography";
import UploadDocument from "./UploadDocument";
import Button from "style-guide/component/Button";
import ReviewDocument from "./ReviewDocument";

interface IFile {
  file_id: string;
  display_name: string;
  content_type: string;
  file_name: string;
}

const ProjectOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [uploadedFile, setUploadedFile] = useState<IFile[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [objectives, setObjectives] = useState<string[]>([]);
  const [editableSolution, setEditableSolution] = useState<string>("");

  const queryParams = new URLSearchParams(location.search);
  const initialComponent =
    queryParams.get("component") === "review" ? "review" : "upload";

  const [currentComponent, setCurrentComponent] = useState<"upload" | "review">(
    initialComponent
  );
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );
  const { listOfFiles } = useSelector((state: RootState) => state.overviewData);

  useEffect(() => {
    if (listOfFiles?.files) {
      setUploadedFile(listOfFiles.files);
    }
  }, [listOfFiles]);

  const handleBack = () => {
    if (currentComponent === "upload") {
      navigate("/choose_RFP");
    } else {
      navigate("/project_overview?component=upload");
      setCurrentComponent("upload");
    }
  };

  const handleNext = async () => {
    if (currentComponent === "upload") {
      if (uploadedFile) {
        navigate("/project_overview?component=review");
        setCurrentComponent("review");
      }
    } else {
      const data = {
        objectives,
        solution: editableSolution,
      };
      const response = await dispatch(setIntakeObjectives(data));
      if (response === 200) {
        setCurrentStep(2);
        navigate("/build_templates?component=requirement");
      }
    }
  };

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] pt-12 px-6 md:px-12 font-helvetica-neue flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <ProgressBar currentStep={currentStep} />
      <div className="flex-grow">
        {currentComponent === "upload" ? (
          <UploadDocument />
        ) : (
          <ReviewDocument
            objectives={objectives}
            setObjectives={setObjectives}
            editableSolution={editableSolution}
            setEditableSolution={setEditableSolution}
          />
        )}
      </div>

      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <div className="flex flex-row gap-4">
          <Button
            type={
              isLoading ||
              (currentComponent === "upload" &&
                Array.isArray(uploadedFile) &&
                uploadedFile.length === 0)
                ? "Disabled"
                : "Primary"
            }
            size="Large"
            icon={false}
            buttonText="Next"
            onClick={handleNext}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectOverview;
