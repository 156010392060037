import { DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import Button from "style-guide/component/Button";
import Input from "style-guide/component/Input";
import Typography from "style-guide/component/Typography";

interface CriteriaTemplateProps {
  category: { section: string; criteria: string[] }[];
  setCategory: React.Dispatch<
    React.SetStateAction<{ section: string; criteria: string[] }[]>
  >;
}

const CriteriaTemplate: React.FC<CriteriaTemplateProps> = ({
  category,
  setCategory,
}) => {
  const [criteria, setCriteria] = useState<string[]>([]);
  const [newCriteria, setNewCriteria] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<{
    index: number;
    section: string;
    criteria: string[];
  }>({
    index: 0,
    section: category[0]?.section || "",
    criteria: category[0]?.criteria || [],
  });
  const { generateCriteria } = useSelector(
    (state: RootState) => state.buildData
  );

  useEffect(() => {
    if (generateCriteria?.length > 0) {
      const formattedSections = generateCriteria.map((item: any) => ({
        section: item.section,
        criteria: item.criteria || [],
      }));
      setCategory(formattedSections);

      setSelectedSection({
        index: 0,
        section: formattedSections[0]?.section || "",
        criteria: formattedSections[0]?.criteria || [],
      });
      setCriteria(formattedSections[0]?.criteria || []);
    }
  }, [generateCriteria]);

  const handleAddCriteria = () => {
    if (newCriteria.trim()) {
      const updatedCriteria = [...selectedSection.criteria, newCriteria.trim()];

      setCriteria(updatedCriteria);

      const updatedCategory = category.map((section, index) =>
        index === selectedSection.index
          ? { ...section, criteria: updatedCriteria }
          : section
      );

      setCategory(updatedCategory);
      setSelectedSection((prev) => ({
        ...prev,
        criteria: updatedCriteria,
      }));
      setNewCriteria("");
    }
  };

  const handleRemoveSection = (index: number) => {
    const updatedCriteria = selectedSection.criteria.filter(
      (_, i) => i !== index
    );
    setCriteria(updatedCriteria);

    const updatedCategory = category.map((section, i) =>
      i === selectedSection.index
        ? { ...section, criteria: updatedCriteria }
        : section
    );

    setCategory(updatedCategory);
    setSelectedSection((prev) => ({
      ...prev,
      criteria: updatedCriteria,
    }));
  };

  const handleSelectSection = (index: number) => {
    const section = category[index];
    setSelectedSection({
      index,
      section: section.section,
      criteria: section.criteria,
    });
    setCriteria(section.criteria);
  };

  return (
    <div className="flex flex-col gap-8">
      {/* Header Section */}
      <div className="flex flex-col gap-2">
        <Typography
          variant="Heading 3"
          text={`Next, let’s add your criteria for the section, "${selectedSection?.section}"`}
        />
        <Typography
          variant="LG"
          text="Criteria are the specific aspects of each section (i.e. category) that you’d like to focus on in your evaluation."
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Left Section - Suggestions */}
        <div className="flex flex-col gap-4">
          <Typography
            variant="LG"
            text="Select from our personalized recommendations or add your own."
            className="mb-4"
          />
          <div className="flex flex-row gap-4 mb-2">
            <Input
              placeholder="Write a criteria here"
              value={newCriteria}
              onChange={(e) => setNewCriteria(e.target.value)}
            />
            <Button
              buttonText="Add"
              onClick={handleAddCriteria}
              type={newCriteria.trim() ? "Primary" : "Disabled"}
              size="Small"
            />
          </div>
          <div className="flex flex-col gap-2">
            {criteria?.map((suggestion, index) => (
              <div key={index} className="flex flex-row gap-4">
                <div className="border border-gray-300 p-2 rounded-lg w-full sm:w-[300px] md:w-[400px] lg:w-[650px]">
                  <Typography text={suggestion} variant="Base" />
                </div>
                <Button
                  type="Default"
                  size="Medium"
                  icon
                  className="border-[1px] border-[#D9D9D9] text-black"
                  iconComponent={<DeleteOutlined />}
                  onClick={() => handleRemoveSection(index)}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Right Section - Added category */}
        <div className="lg:col-span-2 bg-white p-6 rounded-lg shadow">
          {category?.length === 0 ? (
            <Typography
              variant="Body"
              text="No category added yet. Start by selecting or adding a section."
              className="text-gray-500"
            />
          ) : (
            category.map(({ section, criteria }, index) => (
              <div
                key={index}
                className={`mb-6 border p-4 rounded-lg cursor-pointer ${
                  selectedSection.index === index
                    ? "border-blue-primary"
                    : "opacity-45"
                }`}
                onClick={() => handleSelectSection(index)}
              >
                {/* Section Title */}
                <div className="flex justify-between items-center mb-4">
                  <Typography text={section} variant="Heading 4" />
                </div>

                {/* Table for Criteria */}
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border border-gray-200 rounded-lg">
                    <thead className="text-left text-sm font-medium">
                      <tr className="h-[50px]">
                        <th className="px-4 py-2 border-b w-60">
                          <Typography text="Criteria" variant="Base" strong />
                        </th>
                        <th className="px-4 py-2 border-b w-88">
                          <Typography
                            text="Requirements"
                            variant="Base"
                            strong
                          />
                        </th>
                        <th className="px-4 py-2 border-b w-30">
                          <Typography text="Weight" variant="Base" strong />
                        </th>
                        <th className="px-4 py-2 border-b">
                          <Typography text="Scores" variant="Base" strong />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {criteria.map((item, criteriaIndex) => (
                        <tr className="h-[50px]" key={criteriaIndex}>
                          <td className="px-4 py-2 border-b w-60 border-r">
                            <Typography text={item} variant="Base" strong />
                          </td>
                          <td className="px-4 py-2 border-b w-88 border-r" />
                          <td className="px-4 py-2 border-b w-30 border-r" />
                          <td className="px-4 py-2 border-b" />
                        </tr>
                      ))}
                      {criteria?.length < 2 &&
                        Array.from({ length: 2 - criteria?.length }).map(
                          (_, emptyIndex) => (
                            <tr
                              className="h-[50px]"
                              key={`empty-${index}-${emptyIndex}`}
                            >
                              <td className="px-4 py-2 border-b w-60 border-r" />
                              <td className="px-4 py-2 border-b w-88 border-r" />
                              <td className="px-4 py-2 border-b w-30 border-r" />
                              <td className="px-4 py-2 border-b" />
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CriteriaTemplate;
