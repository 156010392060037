import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "style-guide/component/Typography";
import ProgressBar from "style-guide/component/Progressbar";
import Button from "style-guide/component/Button";
import Menu from "style-guide/component/Menu";
import CategoryTemplate from "./CategoryTemplate";
import CriteriaTemplate from "./CriteriaTemplate";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  generateCriteria,
  generateSections,
  getCoreBusinessCriteria,
  getCoreBusinessSection,
} from "../../../../redux/slice/build";
import Loading from "style-guide/component/Loader";

const Template: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [currentStep, setCurrentStep] = useState<number>(2);
  const [sections, setSections] = useState<string[]>([]);
  const [category, setCategory] = useState<
    { section: string; criteria: string[] }[]
  >([]);
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );
  const queryParams = new URLSearchParams(location.search);
  const initialComponent =
    queryParams.get("component") === "criteria" ? "criteria" : "category";

  const [currentComponent, setCurrentComponent] = useState<
    "category" | "criteria"
  >(initialComponent);

  const handleNext = async () => {
    if (currentComponent === "category") {
      const response = await dispatch(generateSections(sections));
      if (response === 200) {
        setCurrentComponent("criteria");
        navigate("/build_scratch/template/?component=criteria");
      }
    } else if (currentComponent === "criteria") {
      const response = await dispatch(generateCriteria(category));
      if (response === 200) {
        navigate(
          "/build_scratch/requirements_criteira/?component=generateRequirements"
        );
      }
    }
  };

  const handleBack = () => {
    if (currentComponent === "category") {
      navigate("/build_scratch/overview/?component=parseDocument");
      setCurrentStep(1);
    } else {
      setCurrentComponent("category");
      navigate("/build_scratch/template/?component=category");
    }
  };

  useEffect(() => {
    if (currentComponent === "category") {
      dispatch(getCoreBusinessSection());
    } else if (currentComponent === "criteria") {
      dispatch(getCoreBusinessCriteria());
    }
  }, [currentComponent]);

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] py-8 px-16 flex flex-col gap-8">
      {/* Header Section */}
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <ProgressBar currentStep={currentStep} />

      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <>
          {currentComponent === "category" && (
            <CategoryTemplate setSections={setSections} sections={sections} />
          )}
          {currentComponent === "criteria" && (
            <CriteriaTemplate setCategory={setCategory} category={category} />
          )}
        </>
      )}

      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex justify-between items-center bg-white p-4 border-t border-gray-300">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type={isLoading ? "Disabled" : "Primary"}
          size="Large"
          icon={false}
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default Template;
