import WebSocketProvider from "api/websocket";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ProtectedRouteProps {
  component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null
  );

  useEffect(() => {
    const userAuth = document.cookie;
    if (!userAuth) {
      navigate("/");
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [navigate]);

  if (isAuthenticated === null) {
    return null;
  }

  return isAuthenticated ? (
    <WebSocketProvider>
      <Component />
    </WebSocketProvider>
  ) : null;
};

export default ProtectedRoute;
