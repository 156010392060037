import React from "react";
import Typography from "style-guide/component/Typography";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import Loading from "style-guide/component/Loader";

const GenerateRequirements: React.FC = () => {
  const { requirements } = useSelector((state: RootState) => state.buildData);
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  const RequirementKeys = Object.keys(requirements || {});

  return (
    <>
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          {/* Instruction Section */}
          <div className="gap-2 flex flex-col justify-between">
            <Typography
              text="Let’s create tailored requirements for your criteria."
              variant="Heading 3"
              className="text-black"
            />
            <Typography
              text="We’ll use the information you’ve provided along with industry best practices to generate customized requirements that match your specific needs."
              variant="LG"
              className="text-black"
            />
          </div>

          {/* Mapping requirements and criteria */}
          <div className="w-full flex pt-4 flex-col gap-8 border-[#F0F0F0] border-[1px] rounded-md">
            {RequirementKeys.map((reqKey) => (
              <div className="w-full" key={reqKey}>
                <Typography
                  text={reqKey}
                  variant="Heading 4"
                  className="pl-4"
                />
                <table className="w-full rounded-md">
                  <thead>
                    <tr>
                      <th className="p-4 text-left">
                        <Typography variant="Base" text="Criteria" strong />
                      </th>
                      <th className="p-4 text-left">
                        <Typography variant="Base" text="Requirements" strong />
                      </th>
                      <th className="p-4 text-left">
                        <div>
                          <Typography variant="Base" text="Weight" strong />
                        </div>
                      </th>
                      <th className="p-4 text-left">
                        <Typography variant="Base" text="Scorers" strong />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(requirements[reqKey] || {}).map(
                      (criteriaKey) => {
                        return (
                          <tr key={criteriaKey} className="h-[50px]">
                            <td className="border border-[#0000000F] px-4 w-[340px] h-full">
                              <Typography
                                variant="Base"
                                text={criteriaKey}
                                strong
                              />
                            </td>
                            <td className="border border-[#0000000F] w-[640px] h-full">
                              <div className="w-full p-2 rounded box-sizing">
                                {/* <div className="flex flex-wrap gap-2 mb-2">
                                {currentComponent === "requirement" && (
                                  <>
                                    {showInput[`${reqKey}-${criteriaKey}`] ? (
                                      <input
                                        className="w-full border-none outline-none p-1 rounded text-[12px]"
                                        placeholder="Add requirement"
                                        value={inputValue}
                                        onChange={(e) =>
                                          setInputValue(e.target.value)
                                        }
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, reqKey, criteriaKey)
                                        }
                                        onBlur={() =>
                                          setShowInput((prev) => ({
                                            ...prev,
                                            [`${reqKey}-${criteriaKey}`]: false,
                                          }))
                                        }
                                        autoFocus
                                      />
                                    ) : (
                                      <div
                                        className="flex flex-row gap-2 items-center bg-[#0000000F] rounded-md px-2 py-1 cursor-pointer"
                                        onClick={() =>
                                          handleAddClick(reqKey, criteriaKey)
                                        }
                                      >
                                        <Typography
                                          variant="SM"
                                          text="Add requirement"
                                          strong
                                        />
                                        <PlusOutlined className="h-3 w-3" />
                                      </div>
                                    )}
                                  </>
                                )}
                                {(
                                  chipsData[reqKey]?.[criteriaKey]
                                    ?.requirements || []
                                ).map((chip: string, chipIndex: number) => (
                                  <div
                                    key={chipIndex}
                                    className="flex items-center bg-[#0000000F] rounded-md px-2 py-1"
                                  >
                                    <Typography variant="SM" text={chip} />
                                    {currentComponent === "requirement" && (
                                      <CloseOutlined
                                        className="ml-1 cursor-pointer h-3 w-3"
                                        onClick={() =>
                                          handleDeleteChip(
                                            reqKey,
                                            criteriaKey,
                                            chipIndex
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                ))}
                              </div> */}
                              </div>
                            </td>

                            <td className="w-[150px] border p-2 text-center h-full">
                              <input className="w-full h-full border-none outline-none p-1 rounded" />
                            </td>
                            <td className="w-[150px] border p-2 text-center h-full">
                              <input className="w-full h-full border-none outline-none p-1 rounded" />
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default GenerateRequirements;
