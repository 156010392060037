import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getVendorSummary } from "../../redux/slice/evaluation";
import Breadcrumb from "style-guide/component/Breadcrumb";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";

const VendorSummary: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const searchParams = new URLSearchParams(location.search);
  const vendor = searchParams.get("vendor");
  const { vendorDetail } = useSelector(
    (state: RootState) => state.evaluationData
  );

  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  const formatString = (str: string) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Generate Table of Contents dynamically
  const tableOfContents = vendorDetail
    ? Object.keys(vendorDetail).map((key) => ({
        label: formatString(key),
        value: key,
      }))
    : [];

  useEffect(() => {
    if (vendor) {
      dispatch(getVendorSummary(vendor));
    }
  }, [vendor, dispatch]);

  const handleScrollToSection = (section: string) => {
    if (sectionRefs.current[section]) {
      sectionRefs.current[section]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const renderAllSections = () => {
    if (!vendorDetail) return null;

    return Object.entries(vendorDetail).map(([section, content]) => (
      <div
        key={section}
        ref={(el) => (sectionRefs.current[section] = el)}
        className="p-4 border-b border-gray-200"
      >
        <Typography
          variant="Heading 4"
          text={formatString(section)}
          className="mb-2 text-[#000000BF]"
        />
        {Array.isArray(content) ? (
          // If content is an array, map through each item and display in <li>
          <ul className="list-disc pl-6">
            {content.map((item, index) => (
              <li key={index} className="mb-2">
                {typeof item === "object" ? (
                  Object.entries(item).map(([key, value]) => (
                    <div key={key} className="flex flex-row gap-2">
                      {/* <Typography
                        variant="Base"
                        text={`${formatString(key)}:`}
                        className="font-bold"
                      /> */}
                      <Typography variant="Base" text={String(value)} />
                    </div>
                  ))
                ) : (
                  <Typography variant="Base" text={String(item)} />
                )}
              </li>
            ))}
          </ul>
        ) : typeof content === "object" && content !== null ? (
          // If content is an object, display its key-value pairs
          <div className="flex flex-col gap-2">
            {Object.entries(content).map(([key, value]) => (
              <div key={key} className="flex flex-col gap-2">
                <Typography
                  variant="Base"
                  text={`${formatString(key)}:`}
                  className="font-bold"
                />
                {Array.isArray(value) ? (
                  <ul className="list-disc pl-6">
                    {value.map((item, index) => (
                      <li key={index} className="mb-2">
                        <Typography variant="Base" text={String(item)} />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant="Base" text={String(value)} />
                )}
              </div>
            ))}
          </div>
        ) : (
          // If it's a string or a primitive value, display directly
          <Typography variant="Base" text={String(content)} />
        )}
      </div>
    ));
  };

  return (
    <div className="flex flex-col">
      <div className="w-full gap-2 flex flex-col p-8">
        <Breadcrumb
          items={[
            {
              label: "Marketing Automation Solution Evaluation",
              link: "/evaluation",
              isActive: false,
            },
            { label: "Scorecard", isActive: false },
            { label: "Report", link: "/", isActive: true },
          ]}
          theme="light"
        />
        <div className="flex flex-row justify-between">
          <Typography
            variant="Heading 2"
            text="Marketing Automation Solution Evaluation"
          />
          <div className="flex flex-row gap-4">
            <Button buttonText="Download" type="Default" />
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="w-full p-6 flex flex-row gap-10">
        {/* Table of Contents */}
        <div className="w-[25%] flex flex-col gap-2 p-4">
          <Typography
            variant="Heading 5"
            text="Table of Contents"
            className="text-[#000000BF]"
          />
          <div className="flex flex-col gap-2">
            {tableOfContents.map((item, index) => (
              <div
                key={index}
                className="p-2 cursor-pointer hover:text-blue-primary"
                onClick={() => handleScrollToSection(item.value)}
              >
                <Typography variant="LG" text={item.label} />
              </div>
            ))}
          </div>
        </div>

        {/* Dynamic Content */}
        <div className="w-full flex flex-col gap-8">{renderAllSections()}</div>
      </main>
    </div>
  );
};

export default VendorSummary;
