import React from "react";
import Button from "style-guide/component/Button";
import TextArea from "style-guide/component/Textarea";
import Typography from "style-guide/component/Typography";

interface QuestionProps {
  question: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

const Question: React.FC<QuestionProps> = ({
  question,
  placeholder,
  value,
  onChange,
}) => {
  // Handler for setting the placeholder value in the textarea
  const handleUseExample = () => {
    onChange(placeholder);
  };

  return (
    <div className="flex flex-col gap-4">
      <Typography variant="Heading 4" text={question} />
      <TextArea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        size="Large"
        className="text-sm w-full"
      />
      <div className="flex flex-col gap-2">
        <Typography variant="Base" text="Example:" strong />
        <Typography variant="Base" text={placeholder} />
        <Button
          type="Primary"
          buttonText="Use Example"
          onClick={handleUseExample}
          className="w-fit"
        />
      </div>
    </div>
  );
};

export default Question;
