import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useNavigate } from "react-router-dom";
import Button from "style-guide/component/Button";
import Input from "style-guide/component/Input";
import Typography from "style-guide/component/Typography";
import { login } from "../../redux/slice/application";
import Loading from "style-guide/component/Loader";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  function getCookie(cookieName: string) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${cookieName}=`)) {
        return cookie.substring(cookieName.length + 1);
      }
    }
    return null;
  }

  const cookieName = "token";
  const cookieValue = getCookie(cookieName);

  const handleLogin = () => {
    dispatch(login({ login: email, password: passwordValue }));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev); // Toggle the password visibility state
  };

  useEffect(() => {
    if (cookieValue !== null) {
      navigate("/landing_page");
    }
  }, [cookieValue, navigate]);

  return (
    <div className="w-full h-screen px-2 flex gap-8 justify-center items-center bg-[#fff]">
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="xl:basis-2/6 lg:basis-3/6 w-full h-fit vh px-4 flex flex-col items-start justify-start space-y-12">
          <div>
            <Typography
              text="Welcome!"
              variant="Heading 1"
              className="text-blue-primary"
              strong
            />
            <Typography
              text="Enter your credentials."
              variant="Heading 5"
              className="text-black"
            />
          </div>
          <div className="w-full">
            <Typography text="Email" variant="Heading 5" />
            <Input
              placeholder="Enter your email.."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              state="Default"
              size="Medium"
              className="w-full"
            />
          </div>
          <div className="w-full relative">
            <Typography text="Password" variant="Heading 5" />
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password..."
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              onKeyDown={handleKeyDown}
              state="Default"
              size="Medium"
              theme="light"
              className="w-full"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-8"
            >
              {showPassword ? (
                <span>
                  <EyeOutlined />
                </span>
              ) : (
                <span>
                  <EyeInvisibleOutlined />
                </span>
              )}
            </button>
          </div>
          <div className="w-full">
            <Button
              type="Primary"
              size="Large"
              icon={false}
              onClick={handleLogin}
              buttonText="Login"
              theme="light"
              className="w-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
