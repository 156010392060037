import React from "react";

interface ProgressBarProps {
  value: number; // Current progress value (e.g., current question number)
  max: number; // Total steps/questions
}

const QuestionProgress: React.FC<ProgressBarProps> = ({ value, max }) => {
  return (
    <div className="flex gap-2">
      {Array.from({ length: max }).map((_, index) => (
        <div
          key={index}
          className={`h-2 flex-1 rounded-md ${
            index < value ? "bg-blue-primary" : "bg-gray-200"
          }`}
        />
      ))}
    </div>
  );
};

export default QuestionProgress;
