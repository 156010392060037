import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // To read query params
import { RootState } from "redux/store";
import Loading from "style-guide/component/Loader";
import Menu from "style-guide/component/Menu";
import ProgressBar from "style-guide/component/Progressbar";
import Typography from "style-guide/component/Typography";
import Button from "style-guide/component/Button";
import InviteStakeholder from "./Invite";

const Invite = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(4);
  const [Finish, setFinish] = useState<boolean>(false);

  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );
  // const { evalScores } = useSelector(
  //   (state: RootState) => state.evaluationData
  // );
  const handleBack = () => {
    setCurrentStep(2);
    navigate("/upload_response");
  };

  const handleNext = async () => {
    setTimeout(() => {
      setFinish(false);
      // if(evalScores && Object.keys(evalScores).length === 0){
      //   navigate("/evaluation");
      // } else {
      navigate("/evaluation");
      // }
    }, 3000);
    setFinish(true);
  };

  return (
    <>
      {Finish ? (
        <div className="fixed inset-0 flex items-center justify-center flex-col gap-2">
          <Loading />
          <Typography
            text="Customizing your evaluation space..."
            variant="XS"
          />
        </div>
      ) : (
        <div className="overflow-x-auto w-full min-h-screen pb-[100px] pt-12 px-6 md:px-12 font-helvetica-neue flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            <Typography
              text="Nozma"
              variant="LG"
              className="text-blue-primary"
              strong
            />
            <Menu />
          </div>
          <ProgressBar currentStep={currentStep} />
          {isLoading && (
            <div className="fixed inset-0 flex items-center justify-center">
              <Loading />
            </div>
          )}

          <div className="flex-grow">
            <InviteStakeholder />
          </div>

          <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
            <Button
              type="Default"
              size="Large"
              icon={false}
              buttonText="Back"
              onClick={handleBack}
            />
              <Button
                type="Primary"
                size="Large"
                icon={false}
                buttonText="Finish"
                onClick={handleNext}
              />
          </div>
        </div>
      )}
    </>
  );
};

export default Invite;
