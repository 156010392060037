import React from "react";

interface TooltipProps {
  text: string;
  position?: "top" | "bottom" | "left" | "right";
  visible?: boolean; // New Prop to control visibility
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  position = "top",
  visible = true,
  children,
}) => {
  return (
    <div className={`relative inline-block ${visible ? "group" : ""}`}>
      {/* Tooltip Text */}
      {visible && (
        <div
          className={`absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-sm px-3 py-1 rounded shadow-lg z-10 whitespace-nowrap
          ${
            position === "top"
              ? "bottom-full left-1/2 transform -translate-x-1/2 mb-2"
              : ""
          }
          ${
            position === "bottom"
              ? "top-full left-1/2 transform -translate-x-1/2 mt-2"
              : ""
          }
          ${
            position === "left"
              ? "right-full top-1/2 transform -translate-y-1/2 mr-2"
              : ""
          }
          ${
            position === "right"
              ? "left-full top-1/2 transform -translate-y-1/2 ml-2"
              : ""
          }`}
        >
          {text}
        </div>
      )}

      {/* Tooltip Trigger */}
      {children}
    </div>
  );
};

export default Tooltip;
