import { DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Button from "style-guide/component/Button";
import Input from "style-guide/component/Input";
import Typography from "style-guide/component/Typography";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface CategoryTemplateProps {
  sections: string[];
  setSections: React.Dispatch<React.SetStateAction<string[]>>;
}

const CategoryTemplate: React.FC<CategoryTemplateProps> = ({
  sections,
  setSections,
}) => {
  const [newSection, setNewSection] = useState<string>("");
  const { generateSection } = useSelector(
    (state: RootState) => state.buildData
  );

  useEffect(() => {
    if (generateSection?.sections?.length > 0) {
      setSections(generateSection?.sections);
    }
  }, [generateSection]);

  const handleAddSection = () => {
    if (newSection.trim()) {
      setSections([...sections, newSection]);
      setNewSection("");
    }
  };

  const handleRemoveSection = (index: number) => {
    setSections(sections.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-col gap-8">
      {/* Header Section */}
      <div className="flex flex-col gap-2">
        <Typography
          variant="Heading 3"
          text="Start by adding sections to organize your evaluation."
        />
        <Typography
          variant="LG"
          text="Sections are high-level categories for evaluating your vendors."
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Left Section - Suggestions */}
        <div className="flex flex-col gap-4">
          <Typography
            variant="LG"
            text="Select from our personalized recommendations or add your own."
            className="mb-4"
          />
          <div className="flex flex-row gap-4 mb-2">
            <Input
              placeholder="Write a section here"
              value={newSection}
              onChange={(e) => setNewSection(e.target.value)}
            />
            <Button
              buttonText="Add"
              onClick={handleAddSection}
              type={newSection.trim() ? "Primary" : "Disabled"}
              size="Small"
            />
          </div>
          <div className="flex flex-col gap-2">
            {sections?.map((suggestion, index) => (
              <div key={index} className="flex flex-row gap-4">
                <div className="border border-gray-300 p-2 rounded-lg w-full sm:w-[300px] md:w-[400px] lg:w-[650px]">
                  <Typography text={suggestion} variant="Base" />
                </div>
                <Button
                  type="Default"
                  size="Medium"
                  icon
                  className="border-[1px] border-[#D9D9D9] text-black"
                  iconComponent={<DeleteOutlined />}
                  onClick={() => handleRemoveSection(index)}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Right Section - Added Sections */}
        <div className="lg:col-span-2 bg-white p-6 rounded-lg shadow">
          {sections?.length === 0 ? (
            <Typography
              variant="Body"
              text="No sections added yet. Start by selecting or adding a section."
              className="text-gray-500"
            />
          ) : (
            sections?.map((section, index) => (
              <div key={index} className="mb-6">
                {/* Section Title */}
                <div className="flex justify-between items-center mb-4">
                  <Typography text={section} variant="Heading 4" />
                </div>

                {/* Table Structure */}
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border border-gray-200 rounded-lg">
                    <thead className="text-left text-sm font-medium">
                      <tr className="h-[50px]">
                        <th className="px-4 py-2 border-b w-60">
                          <Typography text="Criteria" variant="Base" strong />
                        </th>
                        <th className="px-4 py-2 border-b w-88">
                          <Typography
                            text="Requirements"
                            variant="Base"
                            strong
                          />
                        </th>
                        <th className="px-4 py-2 border-b w-30">
                          <Typography text="Weight" variant="Base" strong />
                        </th>
                        <th className="px-4 py-2 border-b">
                          <Typography text="Scores" variant="Base" strong />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm">
                      <tr className="h-[50px]">
                        <td className="px-4 py-2 border-b w-60 border-r" />
                        <td className="px-4 py-2 border-b w-88 border-r" />
                        <td className="px-4 py-2 border-b w-30 border-r" />
                        <td className="px-4 py-2 border-b" />
                      </tr>
                      <tr className="h-[50px]">
                        <td className="px-4 py-2 border-b w-60 border-r" />
                        <td className="px-4 py-2 border-b w-88 border-r" />
                        <td className="px-4 py-2 border-b w-30 border-r" />
                        <td className="px-4 py-2 border-b" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryTemplate;
