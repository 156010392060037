import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    window.location.reload();

    setTimeout(() => {
      navigate("/");
    }, 100);
  };

  return (
    <div className="relative">
      {/* Profile Icon */}
      <div
        className="w-[32px] h-[32px] bg-blue-primary rounded-full text-white flex justify-center items-center cursor-pointer"
        onClick={toggleMenu}
      >
        N
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg border border-gray-200 z-10">
          <ul className="p-2">
            <li
              className="px-4 py-2 hover:bg-blue-primary hover:text-white cursor-pointer rounded"
              onClick={handleLogout}
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Menu;
