import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

interface WebSocketContextProps {
  message: string | object | null;
  sendMessage: (msg: string) => void;
}

// Helper function to get cookie value
function getCookie(cookieName: string) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${cookieName}=`)) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return null;
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(
  undefined
);

const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [message, setMessage] = useState<string | object | null>(null);
  const socketRef = useRef<WebSocket | null>(null);
  const heartbeatRef = useRef<NodeJS.Timeout | null>(null);

  const connectWebSocket = useCallback(() => {
    const sessionId = getCookie("session_id");
    if (sessionId) {
      const socketUrl = `wss://dev.ampai.ai/ws/${sessionId}/`;
      socketRef.current = new WebSocket(socketUrl);

      socketRef.current.addEventListener("open", () => {
        heartbeatRef.current = setInterval(() => {
          if (socketRef.current?.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ type: "ping" }));
          }
        }, 10000);
      });

      socketRef.current.addEventListener("message", (event) => {
        try {
          setMessage(event.data);
        } catch (error) {
          setMessage(event.data);
        }
      });

      socketRef.current.addEventListener("close", () => {
        clearInterval(heartbeatRef.current as NodeJS.Timeout);
        heartbeatRef.current = null;
        connectWebSocket();
      });

      socketRef.current.addEventListener("error", (error) => {
        console.error("WebSocket error:", error);
      });
    }
  }, []);

  useEffect(() => {
    connectWebSocket();
    return () => {
      socketRef.current?.close();
      if (heartbeatRef.current) {
        clearInterval(heartbeatRef.current);
      }
    };
  }, [connectWebSocket]);

  const sendMessage = useCallback((msg: string) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.send(msg);
    }
  }, []);

  const contextValue = useMemo(() => ({ message, sendMessage }), [message]);

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;

export const useWebSocket = () => {
  const context = React.useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
